#skills-container {
  margin-top: 50px;

  h3 {
    padding-top: 20px;
  }

  aside {
    height: 150px;
    margin-bottom: 30px;
  }

  ul {
    margin-left: 15px;
  }

  li {
    line-height: 1.4em;
  }
}

.circle-progress {
  display: inline-block;
  position: relative;

  div {
    width: 100%;
    position: absolute;
    left: 0;
    top: 30px;
    text-align: center;

    > * {
      display: block;
    }
  }

  span {
    font-size: 0.8em;
  }

  strong:after {
    content: '%';
  }
}

#main-projects {
  .card-content {
    min-height: 246px;
  }

  .card-content p {
    line-height: 1.6em;

    &:not(:last-child) {
      margin-bottom: 1em;
    }
  }

  .card-title {
    color: $color-featured;
  }
}
