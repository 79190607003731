.article {
  margin: 0 0 $block-margin 0;
}

.article-inner {
  overflow: hidden;
}

.article-category {
  float: left;
  line-height: 1em;
  color: #ccc;
  text-shadow: 0 1px #fff;
  margin-left: 8px;

  &:before {
    content: '\2022';
  }
}

.article-header {
  a {
    color: $color-default;
  }

  h1 {
    color: $color-default;
    margin: 0 0 10px 0;
    font-size: 2rem;
    font-weight: 600;
  }
}

.article-meta {
  @include clear-fix();

  font-size: 0.8em;
  padding: 5px;
  border-top: 1px dotted #ccc;
  border-bottom: 1px dotted #ccc;
}

.article-entry {
  @extend %base-style;
  @include clear-fix();

  color: $color-default;
  padding: 0 $article-padding;

  p,
  table {
    line-height: $line-height;
    margin: $line-height 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    line-height: $line-height-title;
    margin: $line-height-title 0;
  }

  a {
    color: $color-link;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  ul,
  ol,
  dl {
    margin-top: $line-height;
    margin-bottom: $line-height;
  }

  img,
  video {
    max-width: 100%;
    height: auto;
    display: block;
    margin: auto;
  }

  iframe {
    border: none;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  th {
    font-weight: bold;
    border-bottom: 3px solid $color-border;
    padding-bottom: 0.5em;
  }

  td {
    border-bottom: 1px solid $color-border;
    padding: 10px 0;
  }

  blockquote {
    font-family: $font-serif;
    font-size: 1.4em;
    margin: $line-height 20px;
    text-align: center;

    footer {
      font-size: $font-size;
      margin: $line-height 0;
      font-family: $font-sans;

      cite {
        &:before {
          content: '—';
          padding: 0 0.5em;
        }
      }
    }
  }
}
.article-more-link {
  padding: 0 $article-padding $article-padding $article-padding;

  .btn {
    padding: 0 1rem;
    letter-spacing: 0px;
  }
}

.article-footer {
  @include clear-fix();

  font-size: 0.85em;
  line-height: $line-height;
  border-top: 1px solid $color-border;
  padding-top: $line-height;
  margin: 0 $article-padding $article-padding;

  a {
    color: $color-grey;
    text-decoration: none;

    &:hover {
      color: $color-default;
    }
  }
}

.article-tag-list-item {
  float: left;
  margin-right: 10px;
}

.article-tag-list-link {
  &:before {
    content: '#';
  }
}

.article-comment-link {
  float: right;

  &:before {
    content: '\f075';
    font-family: $font-icon;
    padding-right: 8px;
  }
}

.article-share-link {
  cursor: pointer;
  float: right;
  margin-left: 20px;

  &:before {
    content: '\f064';
    font-family: $font-icon;
    padding-right: 6px;
  }
}

.article-share-box {
  position: absolute;
  display: none;
  background: #fff;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin-left: -145px;
  overflow: hidden;
  z-index: 1;

  &.on {
    display: block;
  }
}

.article-share-input {
  width: 100%;
  background: none;
  box-sizing: border-box;
  font: 14px $font-sans;
  padding: 0 15px;
  color: $color-default;
  outline: none;
  border: 1px solid $color-border;
  border-radius: 3px 3px 0 0;
  height: 36px;
  line-height: 36px;
}

.article-share-links {
  @include clear-fix();
  background: $color-background;
}

%article-share-link {
  width: 50px;
  height: 40px;
  display: block;
  float: left;
  position: relative;
  color: #999;
  text-shadow: 0 1px #fff;

  &:before {
    font-size: 20px;
    font-family: $font-icon;
    @include absolute-center(20px);
    text-align: center;
    line-height: 1em;
  }

  &:hover {
    color: #fff;
  }
}

.article-share-twitter {
  @extend %article-share-link;

  &:before {
    content: '\f099';
  }

  &:hover {
    background: $color-twitter;
    text-shadow: 0 1px darken($color-twitter, 20%);
  }
}

.article-share-facebook {
  @extend %article-share-link;

  &:before {
    content: '\f09a';
  }

  &:hover {
    background: $color-facebook;
    text-shadow: 0 1px darken($color-facebook, 20%);
  }
}

.article-share-pinterest {
  @extend %article-share-link;

  &:before {
    content: '\f0d2';
  }

  &:hover {
    background: $color-pinterest;
    text-shadow: 0 1px darken($color-pinterest, 20%);
  }
}

.article-share-google {
  @extend %article-share-link;

  &:before {
    content: '\f0d5';
  }

  &:hover {
    background: $color-google;
    text-shadow: 0 1px darken($color-google, 20%);
  }
}

.article-gallery {
  background: #000;
  position: relative;
}

.article-gallery-photos {
  position: relative;
  overflow: hidden;
}

.article-gallery-img {
  display: none;
  max-width: 100%;

  &:first-child {
    display: block;
  }

  img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }
}

#page-nav {
  margin: $block-margin auto;
  background: #fff;
  border: 1px solid $color-border;
  text-align: center;
  color: $color-grey;
  overflow: hidden;

  a,
  span {
    padding: 10px 20px;
    line-height: 1;
    height: 2em;
  }

  a {
    color: #f44336;
    text-decoration: none;

    &:hover {
      background: #f44336;
      color: #fff;
    }
  }
}

/////////////
.archives .header {
  font-size: 1.5em;
  margin: 0;
}

///////

#article-nav {
  .article-nav-link-wrap {
    display: flex;
    padding: 0 0.5rem;

    > i {
      flex: 0 0 auto;
    }

    > span {
      flex: 1 1 auto;
    }
  }

  #article-nav-newer i {
    margin-right: 0.5rem;
  }

  #article-nav-older i {
    margin-left: 0.5rem;
  }

  ul {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
    grid-column-gap: 0;

    li {
      float: none;
    }
  }
}
